import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import {
  postContainer,
  title,
  meta,
  hero,
  content,
  postNavigation,
  previousBtn,
  nextBtn,
} from "../styles/post.module.css"

const BlogPost = ({ data, pageContext }) => {
  const post = data.markdownRemark
  const previousPost = pageContext.previous
  const nextPost = pageContext.next

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.excerpt}
        image={
          post.frontmatter.featuredImage.childImageSharp.gatsbyImageData.src
        }
      />
      <article className={postContainer}>
        <header>
          <h1 className={title}>{post.frontmatter.title}</h1>
          <span className={meta}>
            Published: {post.frontmatter.date} - {post.timeToRead} minute read.
          </span>
          <GatsbyImage
            image={
              post.frontmatter.featuredImage.childImageSharp.gatsbyImageData
            }
            alt={post.frontmatter.featuredImage.name}
            className={hero}
          />
        </header>

        <div
          className={content}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />

        <div className="spacer"></div>

        <div className={postNavigation}>
          <div className={previousBtn}>
            {previousPost !== null && (
              <Link to={previousPost?.fields.slug}>
                <p>Previous Post</p>
                <p>{previousPost?.frontmatter.title}</p>
              </Link>
            )}
          </div>
          <div className={nextBtn}>
            {nextPost !== null && (
              <Link to={nextPost?.fields.slug}>
                <p>Next Post</p>
                <p>{nextPost?.frontmatter.title}</p>
              </Link>
            )}
          </div>
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM Do, YYYY")
        title
        featuredImage {
          name
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
            fixed(width: 1200) {
              src
            }
          }
        }
      }
      excerpt
      timeToRead
    }
  }
`
export default BlogPost
